import { Features } from 'constants/feature_names'
import PropTypes from 'prop-types'

const flagShape = {}

Object.keys(Features).forEach(key => {
  flagShape[key] = PropTypes.bool
})

export const flagDataTypes = PropTypes.shape(flagShape)

// Moment is 3rd party library so I do not think we need to be specific on the data format
export const momentDateObjectType = PropTypes.objectOf(PropTypes.any)

export const dateObjectType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.instanceOf(Date)
])

export const dateRangeOptionsType = PropTypes.arrayOf(
  PropTypes.shape({
    rangeType: PropTypes.string,
    startDate: dateObjectType,
    endDate: dateObjectType
  })
)
