import { string, shape, date, number, objectOf } from 'prop-types'

export const userType = shape({
  id: number,
  email: string,
  account_photo: string,
  created_at: date,
  full_name: string,
  invoice_info: shape({
    address: objectOf(string)
  })
})
