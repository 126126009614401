import academy from './academy.json'
import common from './common.json'
import competitive_intelligence from './competitive_intelligence.json'
import generic from './generic.json'
import registrations from './registrations.json'
import services from './services.json'
import seats from './seats.json'
import market_insights from './market_insights.json'
import keyword_engine from './keyword_engine.json'

export default {
  academy,
  common,
  competitive_intelligence,
  generic,
  registrations,
  services,
  seats,
  keyword_engine,
  market_insights
}
