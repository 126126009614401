import PropTypes from 'prop-types'

export const alertProductType = PropTypes.shape({
  nReviews: PropTypes.number,
  estimatedSales: PropTypes.number,
  dimensionUnit: PropTypes.string,
  country: PropTypes.string,
  isUnavailable: PropTypes.bool,
  name: PropTypes.string,
  weight: PropTypes.number,
  listingQualityScore: PropTypes.number,
  state: PropTypes.string,
  listedAt: PropTypes.number,
  sellerType: PropTypes.string,
  apiUpdatedAt: PropTypes.number,
  imageUrl: PropTypes.string,
  fees: PropTypes.number,
  length: PropTypes.number,
  subCategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      rank: PropTypes.number,
      id: PropTypes.id
    })
  ),
  weightUnit: PropTypes.string,
  width: PropTypes.number,
  noParentCategory: PropTypes.bool,
  categoryNullifiedAt: PropTypes.number,
  estRevenue: PropTypes.number,
  id: PropTypes.string,
  color: PropTypes.string,
  calculatedCategory: PropTypes.string,
  asin: PropTypes.string,
  scrapedAt: PropTypes.string,
  rating: PropTypes.number,
  tier: PropTypes.string,
  brand: PropTypes.string,
  scrapedParentAsin: PropTypes.string,
  multipleSellers: PropTypes.bool,
  hasVariants: PropTypes.bool,
  rawCategory: PropTypes.string,
  sellerName: PropTypes.string,
  rank: PropTypes.number,
  nSellers: PropTypes.number,
  pageAsin: PropTypes.string,
  height: PropTypes.number,
  category: PropTypes.string,
  price: PropTypes.number,
  apiCategory: PropTypes.string,
  net: PropTypes.number,
  product_url: PropTypes.string,
  bsr_product: PropTypes.bool,
  hasRankFromApi: PropTypes.bool,
  currency_code: PropTypes.string
})
export const productAlertType = PropTypes.shape({
  id: PropTypes.number,
  country: PropTypes.string,
  asin: PropTypes.string,
  source: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  sku: PropTypes.string,
  amazon_seller_account_id: PropTypes.number,
  pipelines_id: PropTypes.string
})

const alertDiffShape = PropTypes.shape({
  id: PropTypes.number,
  key: PropTypes.string,
  old_value: PropTypes.string,
  new_value: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string
})

export const alertType = PropTypes.shape({
  id: PropTypes.number,
  product_alert_id: PropTypes.number,
  read_at: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  scraped_at: PropTypes.string,
  product: alertProductType,
  tracker_category: PropTypes.string,
  diffs: PropTypes.arrayOf(alertDiffShape),
  product_alert: productAlertType
})

alertDiffShape.alert = alertType
export const alertDiffType = alertDiffShape
