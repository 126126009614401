import { string, bool, shape, number, array } from 'prop-types'

export const membershipPlanType = shape({
  id: number,
  name: string,
  renewable: bool,
  price: number,
  trial_period_days: number,
  token: string,
  billing_frequency: string,
  has_keyword_spy_access: bool,
  normalized_name: string,
  payment_plan: string,
  calculated_expiration_date: string,
  user_limit: number,
  is_upgradable: bool,
  upgrade_price: number,
  upgrade_interval: string
})

export const membershipInfoType = shape({
  id: number,
  name: string,
  renewable: bool,
  price: number,
  trial_period_days: number,
  token: string,
  billing_frequency: string,
  has_keyword_spy_access: bool,
  normalized_name: string,
  payment_plan: string,
  calculated_expiration_date: string,
  user_limit: number,
  is_upgradable: bool,
  upgrade_price: number,
  upgrade_interval: string,
  admin: bool,
  hasActiveMembership: bool,
  membershipStatus: number,
  tracker_limit: number,
  tracker_count: number,
  availableActions: array,
  daysTilExpiration: number,
  expirationDate: string,
  upgradeDate: string,
  membershipAdmin: bool,
  transactionId: string,
  userLimit: number,
  users: array
})
